import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { parseDate } from '../../../scripts/safeDateParse';

export default class FilterDateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null
    }
  }

  getYearRange = () => {
    let year = (new Date()).getFullYear();
    return `${year - 2}:${year + 5}`;
  }

  handleChange = (filterName, dateSelection) => {
    this.setState({ date: dateSelection });
    this.props.form.setFieldValue(filterName, this.formatDateSelection(dateSelection), false);
    this.props.onFilterChange(filterName, dateSelection);
  }

  formatDateSelection = (dateSelection) => {
    let formattedDate = dateSelection ? format(parseDate(dateSelection), 'MM/dd/yyyy') : '';
    return formattedDate;
  }

  render() {
    const { filter, errors, handleBlur, values } = this.props;

    let initialValue = (values && filter) ? parseDate(values[filter.name]) : null;
    return (
      <Form.Group as={Col} md={4} className="pr-3">
        <Calendar
          className="w-100"
          inputClassName={`sure-impact__calendar-input ${errors[filter.name] ? 'is-invalid' : ''}`}
          panelClassName="sure-impact__calendar-panel"
          name={filter.name}
          value={initialValue}
          onChange={(e) => this.handleChange(filter.name, e.value)}
          onBlur={handleBlur}
          placeholder={filter.displayName}
          mask="99/99/9999"
          showButtonBar
        />
        {errors[filter.name] && <div className="d-block invalid-feedback">{errors[filter.name]}</div>}
      </Form.Group>
    );
  }
}
