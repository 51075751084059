/**
 * Constants to be used throughout the front end application.
 * These constants should match those found in the 'Common' project when applicable
 */

export const LOGIN_REDIRECT_URL = '/callback';

export const userRoles = {
  ANALYTICS_USER: 'AnalyticsUser',
  STAFF_WITH_ALL_CLIENTS: 'StaffWithAllClients',
  STAFF: 'OrganizationStaff',
  MANAGER: 'OrganizationManager',
  ADMIN: 'OrganizationAdministrator',
  ROOT_ADMIN: 'SiteAdministrator'
};

export const userRoleIds = {
  SITE_ADMIN: 1,
  ORG_ADMIN: 2,
  ORG_MANAGER: 3,
  ORG_STAFF: 4,
  STAFF_WITH_ALL_CLIENTS: 5,
  ANALYTICS_USER: 6
}

export const rolePermissionLevels = {
  SITE_ADMIN: 'SiteAdmin',
  ORG_ADMIN: 'OrganizationAdmin',
  ORG_MANAGER: 'OrganizationManager',
  STAFF_WITH_ALL_CLIENTS: "StaffWithAllClients",
  ORG_STAFF: 'OrganizationStaff',
  ANALYTICS_USER: 'AnalyticsUser',
}

export const licenseLevels = {
  UNSPECIFIED: 0,
  FREE: 1,
  PAID: 2
}

export const licenseLevelStrings = {
  0: 'Unspecified',
  1: 'Free',
  2: 'Paid'
}

export const licenseTypes = {
  UNSPECIFIED: 0,
  INDIVIDUAL: 1,
  SPONSOR: 2
}

export const licenseTypeStrings = {
  0: 'Unspecified',
  1: 'Individual',
  2: 'Collaborative'
}

export const pagingOptions = {
  DEFAULT_INDEX_ITEMS: 10,
  DEFAULT_MAX_PAGES: 5
};

export const keywordCategories = {
  CLIENT_HIGHEST_EDUCATION: 'ClientHighestEducation',
  CLIENT_LAST_GRADE_OUTSIDE_US: 'ClientLastGradeOutsideUS',
  CURRENT_GRADE: 'CurrentGrade',
  EMPLOYMENT_STATUS: 'EmploymentStatus',
  GENDER: 'Gender',
  INCOME: 'Income',
  LANGUAGE: 'Language',
  LICENSE_TYPE: 'LicenseType',
  PHONE_TYPE: 'PhoneType',
  PUBLIC_ASSISTANCE: 'PublicAssistance',
  RACE: 'Race',
  RELATIONSHIP: 'Relationship',
  SPECIAL_STATUS: 'SpecialStatus',
  STAFF_EDUCATION_LEVEL: 'StaffEducationLevel',
  STATE: 'State',
  MEASURE_TYPE: 'MeasureType',
  MEASURE_OUTCOME_CATEGORY: 'MeasureOutcomeCategory',
  CUTOFF_SCORE_COMPARISON: 'MeasureCutoffComparison'
};

export const keywordCategoryDisplayNames = {
  [keywordCategories.CLIENT_HIGHEST_EDUCATION]: 'Levels of Education',
  [keywordCategories.CLIENT_LAST_GRADE_OUTSIDE_US]: '5 - Post Secondary',
  [keywordCategories.CURRENT_GRADE]: 'K - 12',
  [keywordCategories.EMPLOYMENT_STATUS]: 'Employment Statuses',
  [keywordCategories.GENDER]: 'Genders',
  [keywordCategories.INCOME]: 'Income Levels',
  [keywordCategories.LANGUAGE]: 'Languages',
  [keywordCategories.LICENSE_TYPE]: 'License Types',
  [keywordCategories.PHONE_TYPE]: 'Phone Types',
  [keywordCategories.PUBLIC_ASSISTANCE]: 'Public Assistance Programs',
  [keywordCategories.RACE]: 'Races',
  [keywordCategories.RELATIONSHIP]: 'Relationships',
  [keywordCategories.SPECIAL_STATUS]: 'Special Statuses',
  [keywordCategories.STAFF_EDUCATION_LEVEL]: 'Education Degrees',
  [keywordCategories.STATE]: 'States',
}

export const inputTypes = {
  SELECT: 'selectInput',
  CHECKBOX_GROUP: 'checkboxGroupInput',
  CHECKBOX: 'checkboxInput',
  INTEGER: 'integerInput',
  DECIMAL: 'decimalInput',
  PHONE_NUMBER: 'phoneNumberInput',
  EMAIL: 'emailInput',
  TEXT: 'textInput',
  TEXT_AREA: 'textAreaInput',
  CURRENCY: 'currencyInput',
  DATE: 'dateInput',
  POSTAL_CODE: 'postalCodeInput',
  RELATIONSHIPS: 'relationshipsInput',
  NO_INPUT: 'noInput',
}

export const inputSizes = {
  XS: '3',
  S: '4',
  M: '6',
  L: '8',
  XL: '12'
}

export const schemaVersions = {
  ACTIVE: 'active',
  DRAFT: 'draft',
};

export const localStorageCacheKeys = {
  LAST_RUN_TIME: 'lastRun-',
  OUTCOME_SUMMARIES: 'outcomeSummaries-',
  SERVICE_HOURS: 'serviceHours-',
  UNIQUE_CLIENT_COUNT: 'uniqueClientCount-',
  UNIQUE_CLIENTS_SERVED_COUNT: 'uniqueClientsServedCount-',
  API_FILTERS: 'apiFilters-',
  PROGRAM_COUNT: 'programCount-',
  PROGRAMS_WITH_ENROLLMENT_COUNT: 'programsWithEnrollmentCount-',
  UNIQUE_CLIENTS_ENROLLED_COUNT: 'uniqueClientsEnrolledCount-',
  SERVICE_HOURS_FILTERS: 'serviceHourFilters',
  COST_PER_SUCCESS_FILTERS: 'costPerSuccessFilters',
  LOCAL_VERSION_OUTDATED: 'localVersionOutdated',
}

export const dashboardCardTitles = {
  UNIQUE_CLIENTS: 'Unique Clients in SureImpact',
  PROGRAMS_WITH_ENROLLMENT: 'Programs With Enrollment',
  UNIQUE_CLIENTS_ENROLLED: 'Unique Clients Enrolled in Programs ',
  UNIQUE_CLIENTS_SERVED: 'Unique Clients Served'
}

export const INPUT_LIST_TYPES = ['checkboxGroupInput'];

export const apiFilterInputTypes = {
  SINGLE_SELECT: 0,
  MULTI_SELECT: 1,
  DATE: 2
}

export const apiFilterTypes = {
  ORGANIZATION: 'Organization',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  PROGRAM: 'Program',
  ACTIVE_STATUS: 'Active Status',
  RACE: 'Race',
  HISPANIC_LATINO: 'Is Hispanic or Latino?',
  GENDER: 'Gender',
  COLLABORATIVE_ORGANIZATION: 'Collaborative Organization'
}

export const apiFilterNames = {
  ORGANIZATION: 'organization',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  PROGRAM: 'program',
  ACTIVE_STATUS: 'activeStatus',
  RACE: 'race',
  HISPANIC_LATINO: 'hispanicLatino',
  GENDER: 'gender',
  COLLABORATIVE_ORGANIZATION: 'collaborativeOrganization'
}

export const MeasureInclusionTypes = {
  NONE: 0,
  ALL_GLOBAL: 1,
  ALL_GLOBAL_AND_SPONSORED: 2,
  PROGRAM_LIMITED_GLOBAL: 3,
  PROGRAM_LIMITED_GLOBAL_AND_SPONSORED: 4
}

export const filterRange = [
  {label: "None", value: "None"},
  {label: "Last 7 Days", value: "Last7Days"},
  {label: "Last 30 Days", value: "Last30Days"},
  {label: "Last 90 Days", value: "Last90Days"},
  {label: "Last 6 Months", value: "Last6Months"},
  {label: "Last Year", value: "LastYear"},
  {label: "Custom", value: "Custom"},
]

export const externalAnalyticsType = {
  REPORT: 0,
  PAGINATEDREPORT: 1
}
