import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { parseDate } from '../../scripts/safeDateParse';

export default class ServiceActivityDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.activityDateUTC = options.activityDateUTC || '';
    this.activityTimeZone = options.activityTimeZone || '';
    this.durationHours = options.durationHours || '';
    this.durationMinutes = options.durationMinutes || '';
    this.notes = options.notes || '';
    this.applicationUserId = options.applicationUserId || 0;
    this.clientId = options.clientId || 0;
    this.organizationServiceId = options.organizationServiceId || 0;
    this.staffName = options.staffName || '';
    this.additionalClients = options.additionalClients || [];  // used only to persist new activities

    // convert activityDateUTC to local and set activityDate/Time fields
    const addZuluChar = this.activityDateUTC[this.activityDateUTC.length - 1] === 'Z' ? '' : 'Z'; // used to insure server supplied UTC is ISO standard (includes Z time zone)
    const localDate = this.activityDateUTC
      ? utcToZonedTime(`${this.activityDateUTC}${addZuluChar}`, this.activityTimeZone)
      : new Date();

    this.activityDate = format(localDate, 'MM/dd/yyyy');
    this.activityTime = this.activityDateUTC ? format(localDate, 'hh:mm aaa') : '12:00 PM';
  }

  setActivityDateAndTimeZone(activityDate, activityTime) {
    // need to pass in and use activityDate/Time here because the constructor method (setting these values)
    // is used primarily for editing an existing record resulting in erroneous data here
    let date = parseDate(`${activityDate} ${activityTime}`);
    let utcDate = new Date(Date.UTC(date.getFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())).toISOString();
    this.activityTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.activityDateUTC = utcDate;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
